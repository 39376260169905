import { useIdNavigate } from "@/hooks/useIdNavigate";
import { ConversationItemMessage, ShortenedTileList } from "@trunk-tools/ui";
import { useCurrentProject } from "dataHooks";
import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";
import { useParams } from "react-router-dom";
import { DocumentTile } from "../DocumentTile.component";
import { ThinkingMessageContent } from "../Thinking.component";

type RelevantDocumentsConversationItemProps = {
  children: React.ReactNode;
};

const RelevantDocumentsConversationItem = ({
  children,
}: RelevantDocumentsConversationItemProps) => {
  return (
    <ConversationItemMessage
      variant="brand"
      avatarProps={{
        variant: "v2yellowResponsive",
        img: ttMessageAvatarLogo,
        name: "TrunkText",
      }}
      isLastItem
      collapsible
      headerContent={<p className="uppercase">relevant documents</p>}
      collapsedHeaderContent={
        <p className="uppercase">view relevant documents</p>
      }
    >
      {children}
    </ConversationItemMessage>
  );
};

export const RelevantDocumentsMessage = ({
  question,
}: {
  question: {
    id: string;
    question_search_results: Array<{
      id: string;
      document: { id: string };
    }>;
  };
}) => {
  const { currentProject } = useCurrentProject();
  const idNavigate = useIdNavigate();
  const { conversationId } = useParams<{ conversationId: string }>();

  return (
    <RelevantDocumentsConversationItem>
      {question.question_search_results.length > 0 ? (
        <ShortenedTileList
          tiles={question.question_search_results.map((sr) => (
            <DocumentTile
              key={sr.id}
              showSubContent={true}
              questionId={question.id}
              documentId={sr.document.id}
              itemId={sr.id}
              itemType="QUESTION_SEARCH_RESULT_DOCUMENT"
              onClick={() => {
                idNavigate(
                  `/documents/${sr.document.id}?questionId=${question.id}&conversationId=${conversationId}`,
                );
              }}
            />
          ))}
          maxTilesToDisplay={3}
          popupTitle="RELEVANT DOCUMENTS"
          viewButtonText={`VIEW ALL ${question.question_search_results.length} RELEVANT DOCUMENTS`}
          pendoTrackingInformation={{
            viewTilesButtonId: "view-relevant-documents",
          }}
        />
      ) : (
        <ThinkingMessageContent
          id="search-results-thinking"
          text={`searching ${currentProject.name} for documents & info...`}
        />
      )}
    </RelevantDocumentsConversationItem>
  );
};
