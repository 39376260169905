import useSWR from "swr";
import { api } from "./helpers/APIClient";
import { useCurrentProject } from "./project.datahook";
import useSWRMutation from "swr/mutation";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { useUserConversations } from "./conversations.dataHook";

export const useUserProjectAgentConfigs = () => {
  const { currentProject } = useCurrentProject();

  const getUserProjectAgentConfig = async () => {
    const data = await api.userProjectAgentConfigs({
      project_id: currentProject.id,
    });
    return data;
  };

  const updateUserProjectAgentConfigFetch = async (
    key: unknown,
    { arg }: { arg: Parameters<typeof api.updateUserProjectAgentConfigs>[0] },
  ) => {
    const res = await api.updateUserProjectAgentConfigs(arg);
    return res;
  };

  const { data: agentConfigs, mutate: mutateGetUserProjectAgentConfig } =
    useSWR(
      ["userProjectAgentConfig", currentProject.id],
      getUserProjectAgentConfig,
      {
        suspense: true,
      },
    );

  const { trigger: updateUserProjectAgentConfig } = useSWRMutation(
    ["updateUserProjectAgentConfig"],
    updateUserProjectAgentConfigFetch,
  );

  return {
    agentConfigs,
    refetchAgentConfigs: mutateGetUserProjectAgentConfig,
    updateUserProjectAgentConfig,
  };
};

export const useCompileDocuments = ({
  project_id,
  conversation_id,
  schedule_agent_output_id,
}: {
  project_id: string;
  conversation_id: string;
  schedule_agent_output_id: string;
}) => {
  const compileDocumentsFetch = async (
    key: unknown,
    {
      arg,
    }: {
      arg: Omit<
        Parameters<typeof api.compileDocuments>[0],
        "project_id" | "conversation_id" | "schedule_agent_output_id"
      >;
    },
  ) => {
    const res = await api.compileDocuments({
      project_id,
      conversation_id,
      schedule_agent_output_id,
      ...arg,
    });
    return res;
  };

  const { trigger: compileDocuments } = useSWRMutation(
    ["compileDocumentsFetch", schedule_agent_output_id],
    compileDocumentsFetch,
  );

  return {
    compileDocuments,
  };
};

export const useRunAgent = () => {
  const idNavigate = useIdNavigate();
  const { currentProject } = useCurrentProject();
  const { refreshUserConversations } = useUserConversations();

  const runAgentFetch = async (
    key: unknown,
    {
      arg,
    }: {
      arg: Omit<Parameters<typeof api.agentRun>[0], "project_id">;
    },
  ) => {
    const result = await api.agentRun({
      project_id: currentProject.id,
      ...arg,
    });
    idNavigate(`/conversations/${result.conversation_id}`);
    refreshUserConversations();

    return result;
  };

  const { trigger: runAgent } = useSWRMutation(
    ["runAgent", currentProject.id],
    runAgentFetch,
  );

  return {
    runAgent,
  };
};

export const useMarkQuestionBankAgentConversationAsRead = ({
  question_bank_agent_question_ids,
}: {
  question_bank_agent_question_ids: string[];
}) => {
  const { currentProject } = useCurrentProject();

  const request = async () => {
    const result = await api.markQuestionBankAgentConversationRead({
      project_id: currentProject.id,
      question_bank_agent_question_ids: question_bank_agent_question_ids,
    });

    return result;
  };

  const { trigger: markQuestionBankAgentConversationRead } = useSWRMutation(
    ["markQuestionBankAgentConversationRead", question_bank_agent_question_ids],
    request,
  );

  return {
    markQuestionBankAgentConversationRead,
  };
};

export const useMarkScheduleAgentOutputConversationAsRead = ({
  schedule_agent_output_id,
}: {
  schedule_agent_output_id: string;
}) => {
  const { currentProject } = useCurrentProject();

  const request = async () => {
    const result = await api.markScheduleAgentOutputAsRead({
      project_id: currentProject.id,
      schedule_agent_output_id,
    });

    return result;
  };

  const { trigger: markScheduleAgentOutputAsRead } = useSWRMutation(
    [
      "markScheduleAgentOutputAsRead",
      currentProject.id,
      schedule_agent_output_id,
    ],
    request,
  );

  return {
    markScheduleAgentOutputAsRead,
  };
};

export const useUpdateQuestionBankAgent = () => {
  const { currentProject } = useCurrentProject();

  const updateQuestionBankAgentFetch = async (
    key: unknown,
    {
      arg,
    }: {
      arg: Omit<
        Parameters<typeof api.updateQuestionBankAgent>[0],
        "project_id"
      >;
    },
  ) => {
    const result = await api.updateQuestionBankAgent({
      project_id: currentProject.id,
      ...arg,
    });

    return result;
  };

  const { trigger: updateQuestionBankAgent } = useSWRMutation(
    ["updateQuestionBankAgent"],
    updateQuestionBankAgentFetch,
  );

  return {
    updateQuestionBankAgent,
  };
};

export const useCreateQuestionBankAgent = () => {
  const { currentProject } = useCurrentProject();

  const createQuestionBankAgentFetch = async (
    key: unknown,
    {
      arg,
    }: {
      arg: Omit<
        Parameters<typeof api.createQuestionBankAgent>[0],
        "project_id"
      >;
    },
  ) => {
    const result = await api.createQuestionBankAgent({
      project_id: currentProject.id,
      ...arg,
    });

    return result;
  };

  const { trigger: createQuestionBankAgent } = useSWRMutation(
    ["createQuestionBankAgent"],
    createQuestionBankAgentFetch,
  );

  return {
    createQuestionBankAgent,
  };
};

export const useAgentConfig = ({ agentId }: { agentId: string }) => {
  const { currentProject } = useCurrentProject();
  const fetch = async () => {
    if (!agentId) {
      return null;
    }
    const data = await api.getQuestionBankAgent({
      question_bank_agent_id: agentId,
      project_id: currentProject.id,
    });
    return data;
  };

  const { data: agentConfig, mutate } = useSWR(["agent", agentId], fetch, {
    suspense: true,
  });

  return { agentConfig, refetchAgentConfig: mutate };
};

export const useProjectUsers = () => {
  const { currentProject } = useCurrentProject();

  const fetch = async () => {
    const data = await api.getProjectUsers({
      project_id: currentProject.id,
    });
    return data;
  };

  const { data: projectUsers, mutate } = useSWR(
    ["projectUsers", currentProject.id],
    fetch,
    {
      suspense: true,
    },
  );

  return { projectUsers, refetchProjectUsers: mutate };
};
