import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";
import { ConversationScheduleAgentOverviewItem } from "@trunk-tools/txt-shared";
import { Button, ConversationItemMessage, TileList } from "@trunk-tools/ui";
import { DocumentTile } from "../DocumentTile.component";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { useIsTabActive } from "@/hooks/useIsTabActive";
import { DownloadStringAsCSVButton } from "@/components/DownloadStringAsCsv.component";
import { GrDocumentDownload } from "react-icons/gr";
import { FaEye, FaEllipsisV } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import {
  useCompileDocuments,
  useMarkScheduleAgentOutputConversationAsRead,
} from "@/dataHooks/agents.dataHook";
import { useParams } from "react-router-dom";
import { ThinkingMessageContent } from "../Thinking.component";
import { ReactNode, useState, useContext, useEffect, RefObject } from "react";
import { RefContext } from "@/providers/ref.provider";

type TableProps = {
  workActivityTable: ConversationScheduleAgentOverviewItem["work_activity_table"];
  scheduleAgentOutputId: string;
  conversationId: string;
  projectId: string;
  onRefetch: () => void;
};

const DesktopScheduleTable = ({
  workActivityTable,
  scheduleAgentOutputId,
  conversationId,
  projectId,
  onRefetch,
}: TableProps) => {
  const { refs } = useContext(RefContext);

  const { compileDocuments } = useCompileDocuments({
    schedule_agent_output_id: scheduleAgentOutputId,
    conversation_id: conversationId,
    project_id: projectId,
  });

  return (
    <table className="border-separate border-spacing-y-4 min-w-[700px]">
      <thead>
        <tr>
          <th className="text-xs text-left uppercase border-b border-white pr-4 whitespace-nowrap">
            activity id
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            activity name
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            start
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            finish
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            orig dur.
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            % complete
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            remaining dur.
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            early start
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            early finish
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            total float
          </th>
          {/* Note: #1b2925 is the resulting color of bg-500 with 10% opacity */}
          <th className="text-xs text-left uppercase border-b border-white pl-4 whitespace-nowrap sticky right-0  bg-[#1b2925]">
            actions
          </th>
        </tr>
      </thead>
      <tbody>
        {workActivityTable.map((work_activity, i) => {
          let taskButton: ReactNode = null;

          if (work_activity.task.task_status === "COMPLETE") {
            taskButton = (
              <span data-pendo-id="schedule-agent-view-docs">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    const taskId = work_activity.task.id;
                    if (
                      refs[taskId] &&
                      typeof refs[taskId] !== "function" &&
                      (refs[taskId] as RefObject<HTMLElement>).current
                    ) {
                      (
                        refs[taskId] as RefObject<HTMLElement>
                      ).current!.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  icon={FaEye}
                >
                  <div className="text-xs/[10px]">View Docs</div>
                </Button>
              </span>
            );
          } else {
            taskButton = (
              <span data-pendo-id="schedule-agent-compile-all-docs">
                <Button
                  variant="success"
                  size="sm"
                  isDisabled={work_activity.task.task_status === "PENDING"}
                  onClick={async () => {
                    await compileDocuments({
                      task_code: work_activity.task.task_code,
                      task_name: work_activity.task.task_name,
                      work_breakdown_structure_id:
                        work_activity.wbs_info.wbs_id,
                      work_breakdown_structure_name:
                        work_activity.wbs_info.wbs_name,
                      schedule_agent_wbs_task_output_id: work_activity.task.id,
                    });
                    onRefetch();
                  }}
                  icon={AiOutlinePlus}
                >
                  <div className="text-xs/[10px]">Compile All Docs</div>
                </Button>
              </span>
            );
          }

          return (
            <tr key={i}>
              <td className="text-left pr-4 whitespace-nowrap">
                {work_activity.task.task_code}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.task.task_name}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.start.toDateString()}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.finish.toDateString()}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.original_duration_days !== null &&
                  `${work_activity.original_duration_days} days`}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.pct_complete !== null &&
                  `${work_activity.pct_complete} %`}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.remaining_duration_days !== null &&
                  `${work_activity.remaining_duration_days} days`}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.early_start.toDateString()}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.early_finish.toDateString()}
              </td>
              <td className="text-left pl-4 pr-4 whitespace-nowrap">
                {work_activity.total_float !== null &&
                  work_activity.total_float}
              </td>
              {/* Note: #1b2925 is the resulting color of bg-500 with 10% opacity */}
              <td className="text-left pl-4 whitespace-nowrap sticky right-0  bg-[#1b2925]">
                {taskButton}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const MobileScheduleTable = ({
  workActivityTable,
  scheduleAgentOutputId,
  conversationId,
  projectId,
  onRefetch,
}: TableProps) => {
  const [showButtonForTaskId, setShowButtonForTaskId] = useState<string>("");
  const { refs } = useContext(RefContext);

  const { compileDocuments } = useCompileDocuments({
    schedule_agent_output_id: scheduleAgentOutputId,
    conversation_id: conversationId,
    project_id: projectId,
  });

  return (
    <table
      className="border-separate border-spacing-y-4 min-w-[700px]"
      onClick={() => {
        setShowButtonForTaskId("");
      }}
    >
      <thead>
        <tr>
          <th className="text-xs text-left uppercase border-b border-white pr-4 whitespace-nowrap">
            actions
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            activity ID
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            activity name
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            start
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            finish
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            orig dur.
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            % complete
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            remaining dur.
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            early start
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 pr-4 whitespace-nowrap">
            early finish
          </th>
          <th className="text-xs text-left uppercase border-b border-white pl-4 whitespace-nowrap">
            total float
          </th>
        </tr>
      </thead>
      <tbody>
        {workActivityTable.map((work_activity, i) => {
          const handleClick = async () => {
            if (work_activity.task.task_status === "COMPLETE") {
              const taskId = work_activity.task.id;
              if (
                refs[taskId] &&
                typeof refs[taskId] !== "function" &&
                (refs[taskId] as RefObject<HTMLElement>).current
              ) {
                (
                  refs[taskId] as RefObject<HTMLElement>
                ).current!.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            } else if (work_activity.task.task_status !== "PENDING") {
              await compileDocuments({
                task_code: work_activity.task.task_code,
                task_name: work_activity.task.task_name,
                work_breakdown_structure_id: work_activity.wbs_info.wbs_id,
                work_breakdown_structure_name: work_activity.wbs_info.wbs_name,
                schedule_agent_wbs_task_output_id: work_activity.task.id,
              });
              onRefetch();
            }
            setShowButtonForTaskId("");
          };

          const popupMenu = (
            <div
              className="absolute top-0 left-[40px] bg-white py-4 px-6 rounded-md w-[calc(100vw-135px)]"
              onClick={handleClick}
            >
              <div className="text-base text-[#0A545E] font-izoard">
                {work_activity.task.task_status === "COMPLETE"
                  ? "View Docs"
                  : "Compile Docs"}
              </div>
            </div>
          );

          const taskButton = (
            <>
              <div
                onClick={(e) => {
                  setShowButtonForTaskId(work_activity.task.id);
                  e.stopPropagation();
                }}
              >
                <FaEllipsisV
                  size={16}
                  color="#F5BF40"
                  // Only fired on mobile when click events are blocked by icon
                  onTouchStart={(e) => {
                    setShowButtonForTaskId(work_activity.task.id);
                    e.stopPropagation();
                  }}
                />
              </div>
              {showButtonForTaskId === work_activity.task.id && popupMenu}
            </>
          );

          return (
            <tr key={i}>
              <td className="text-left py-4 pr-4 whitespace-nowrap relative">
                {taskButton}
              </td>
              <td className="text-left py-4 pr-4 pl-4 whitespace-nowrap">
                {work_activity.task.task_code}
              </td>
              <td className="text-left py-4 pl-4 pr-4 whitespace-nowrap">
                {work_activity.task.task_name}
              </td>
              <td className="text-left py-4 pl-4 pr-4 whitespace-nowrap">
                {work_activity.start.toDateString()}
              </td>
              <td className="text-left py-4 pl-4 pr-4 whitespace-nowrap">
                {work_activity.finish.toDateString()}
              </td>
              <td className="text-left py-4 pl-4 pr-4 whitespace-nowrap">
                {work_activity.original_duration_days !== null &&
                  `${work_activity.original_duration_days} days`}
              </td>
              <td className="text-left py-4 pl-4 pr-4 whitespace-nowrap">
                {work_activity.pct_complete !== null &&
                  `${work_activity.pct_complete} %`}
              </td>
              <td className="text-left py-4 pl-4 pr-4 whitespace-nowrap">
                {work_activity.remaining_duration_days !== null &&
                  `${work_activity.remaining_duration_days} days`}
              </td>
              <td className="text-left py-4 pl-4 pr-4 whitespace-nowrap">
                {work_activity.early_start.toDateString()}
              </td>
              <td className="text-left py-4 pl-4 pr-4 whitespace-nowrap">
                {work_activity.early_finish.toDateString()}
              </td>
              <td className="text-left py-4 pl-4 whitespace-nowrap">
                {work_activity.total_float !== null &&
                  work_activity.total_float}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

type Props = {
  item: ConversationScheduleAgentOverviewItem;
  conversationId: string;
  onRefetch: () => void;
};

const ScheduleAgentWorkActivityTable = ({
  schedule_agent_output_id,
  work_activity_table,
  onRefetch,
}: {
  schedule_agent_output_id: Props["item"]["schedule_agent_output_id"];
  work_activity_table: Props["item"]["work_activity_table"];
  onRefetch: Props["onRefetch"];
}) => {
  const { conversationId, projectId } = useParams<{
    conversationId: string;
    projectId: string;
  }>();

  return (
    <div
      className="w-full show-scrollbar overflow-x-scroll relative mt-4"
      style={{ transform: "rotateX(180deg)" }}
    >
      <div style={{ transform: "rotateX(180deg)" }}>
        <div className="hidden desktop:block">
          <DesktopScheduleTable
            workActivityTable={work_activity_table}
            scheduleAgentOutputId={schedule_agent_output_id}
            conversationId={conversationId as string}
            projectId={projectId as string}
            onRefetch={onRefetch}
          />
        </div>

        <div className="desktop:hidden block">
          <MobileScheduleTable
            workActivityTable={work_activity_table}
            scheduleAgentOutputId={schedule_agent_output_id}
            conversationId={conversationId as string}
            projectId={projectId as string}
            onRefetch={onRefetch}
          />
        </div>
      </div>
    </div>
  );
};

export const ScheduleAgentOverviewConversationItem = ({
  item,
  conversationId,
  onRefetch,
}: Props) => {
  const {
    schedule_agent_output_id,
    source_schedule,
    overview,
    work_activity_table,
  } = item;
  const idNavigate = useIdNavigate();
  const isTabActive = useIsTabActive();

  const start = new Date(item.schedule_start);
  const end = new Date(item.schedule_end);

  const diffFromCreatedToStart = Math.abs(
    start.getTime() - item.created_at.getTime(),
  );

  const { markScheduleAgentOutputAsRead } =
    useMarkScheduleAgentOutputConversationAsRead({
      schedule_agent_output_id,
    });

  useEffect(() => {
    if (item.status === "FINISHED" && isTabActive) {
      markScheduleAgentOutputAsRead();
    }
  }, [item.status, isTabActive]);

  const diffFromCreatedToEnd = Math.abs(
    end.getTime() - item.created_at.getTime(),
  );

  const title =
    diffFromCreatedToStart < diffFromCreatedToEnd
      ? "Week Schedule Lookahead"
      : "Week Schedule Summary";

  const headerContent = (
    <div className="flex flex-col gap-y-2">
      <div>
        {item.created_at.toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </div>
      <div className="items-center text-lg gap-x-1 font-poppins">
        <span className="font-bold">{`${title}: `}</span>
        <span>
          Work Scheduled for the Week of{" "}
          {`${start.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
          })}`}
        </span>
      </div>
    </div>
  );

  return (
    <ConversationItemMessage
      variant="green"
      avatarProps={{
        variant: "v2yellowResponsive",
        name: "TrunkText",
        img: ttMessageAvatarLogo,
      }}
      collapsible
      highlightOnHoverWhileOpen
      headerContent={headerContent}
      collapsedHeaderContent={headerContent}
    >
      {item.status === "PENDING" && (
        <ThinkingMessageContent
          id="schedule-agent-thinking"
          text={"Generating Response"}
        />
      )}
      {item.status === "FINISHED" && (
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-4">
            <div
              className="flex flex-row items-center desktop:w-[180px]"
              data-pendo-id="download-schedule-agent-csv"
            >
              <DownloadStringAsCSVButton
                icon={GrDocumentDownload}
                filename="schedule"
                csvString={`Task Code,Task Name,Start,Finish,Original Duration (days),% Complete,Remaining Duration (days),Early Start,Early Finish,Total Float\n${work_activity_table
                  .map(
                    ({
                      task,
                      start,
                      finish,
                      original_duration_days,
                      pct_complete,
                      remaining_duration_days,
                      early_start,
                      early_finish,
                      total_float,
                    }) => {
                      return `${task.task_code},${
                        task.task_name
                      },${start.toDateString()},${finish.toDateString()},${
                        original_duration_days || ""
                      },${pct_complete !== null ? pct_complete : ""},${
                        remaining_duration_days || ""
                      },${early_start.toDateString()},${early_finish.toDateString()},${
                        total_float || ""
                      }`;
                    },
                  )
                  .join("\n")}`}
              >
                <p className="uppercase">download .csv</p>
              </DownloadStringAsCSVButton>
            </div>

            <div className="border-green-500 border-t-8 mx-[-24px] mt-6 mb-4" />

            <div className="w-full text-xs uppercase">source schedule</div>
            <div className="w-full text-xs uppercase">
              data date:{" "}
              {item.data_date ? item.data_date.toDateString() : "N/A"}
            </div>

            {/** There's only one document tile, but the tile list already nicely handles how to render on desktop/mobile */}
            <TileList
              tiles={[
                <DocumentTile
                  documentId={source_schedule.document_id}
                  showSubContent={false}
                  onClick={() => {
                    idNavigate(
                      `/documents/${source_schedule.document_id}?conversationId=${conversationId}`,
                    );
                  }}
                />,
              ]}
            ></TileList>
          </div>

          <div className="w-full flex flex-col gap-y-4">
            <div className="text-xs uppercase">overview</div>
            <div className="text-base font-poppins">{overview}</div>
          </div>

          <ScheduleAgentWorkActivityTable
            schedule_agent_output_id={schedule_agent_output_id}
            work_activity_table={work_activity_table}
            onRefetch={onRefetch}
          />
        </div>
      )}
    </ConversationItemMessage>
  );
};
