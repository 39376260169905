/** Sometimes we get strings back like "<ANSWER-WE-GENERATED> SOURCES: s-0, s-1, s-4, s-10" where
 *  the sources that the LLM used are listed at the end. And sometimes it's in Spanish too if the
 * whole answer was requested to be in Spanish.
 */
export const cleanSourcesFromAnswerText = (answerText: string | null) => {
  return answerText
    ?.replaceAll(/SOURCES: /g, "")
    .replaceAll(/FUENTES: /g, "") // Spanish for "SOURCES"
    .replaceAll(/s-[0-9]*,?/g, "")
    .trim();
};
