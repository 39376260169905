import { Tabs } from "@trunk-tools/ui";
import {
  useQuestionDocument,
  useQuestionDocumentResponseCitations,
} from "dataHooks";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDocumentAttachments } from "@/dataHooks/documents.dataHook";
import { DocumentTile } from "../Conversations/DocumentTile.component";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { FeatureFlagGate } from "../FeatureFlagGate.component";

interface Props {
  hideDocInfo: boolean;
  questionId: string;
  documentId: string;
  changePage: (props: { page: number; sourceText?: string }) => void;
}

const DocumentAttachments = ({ documentId }: { documentId: string }) => {
  const { documentIds } = useDocumentAttachments({ documentId });
  const idNavigate = useIdNavigate();

  return documentIds.length === 0 ? (
    <></>
  ) : (
    <div className="flex flex-col gap-y-4 mb-4">
      <div className="uppercase">attachments</div>
      {documentIds.map((id) => (
        <DocumentTile
          documentId={id}
          showSubContent={false}
          onClick={() => {
            idNavigate(`/documents/${id}`);
          }}
        />
      ))}
    </div>
  );
};

const QuestionDocumentInfoOLD = ({
  hideDocInfo,
  questionId,
  documentId,
}: Props) => {
  const { url, questionDocument } = useQuestionDocument({
    questionId: questionId as string,
    documentId: documentId as string,
  });

  if (!questionDocument || !url) {
    return <></>;
  }

  return (
    <div
      className="desktop:min-w-[400px] mx-10 transition-all ease-in-out duration-[600ms] data-[state=true]:max-h-0 data-[state=true]:opacity-0 data-[state=true]:max-w-0 data-[state=true]:min-w-0 data-[state=true]:mx-0"
      data-state={hideDocInfo}
    >
      <DocumentAttachments documentId={documentId} />

      {[
        { label: "Doc Name", text: questionDocument.document.name },
        { label: "Doc Type", text: questionDocument.document.type },
      ].map((m) => (
        <div key={m.label.replaceAll(" ", "_")}>
          <div className="text-lg mb-2">{m.label}</div>
          <div className="text-sm mb-4">{m.text}</div>
        </div>
      ))}
    </div>
  );
};

const QuestionDocumentHighlights = ({
  questionId,
  documentId,
  changePage,
}: Props) => {
  const { citations } = useQuestionDocumentResponseCitations({
    questionId: questionId as string,
    documentId: documentId as string,
  });
  const [currentCitationIdx, setCurrentCitationIdx] = useState(0);

  const updatePageBasedOnCitation = () => {
    if (citations.length === 0) return;

    const currentCitation = citations[currentCitationIdx];
    if (currentCitation.locator) {
      changePage({
        page: Number(citations[currentCitationIdx].locator),
        sourceText: currentCitation.source_text,
      });
    }
  };

  useEffect(() => {
    updatePageBasedOnCitation();
  }, [currentCitationIdx]);

  if (citations.length === 0) {
    return <div>This document was not used as a source for the question.</div>;
  }

  return (
    <>
      {citations.map((citation, idx) => {
        return (
          <div
            className={`flex flex-col gap-y-2 ${
              idx === currentCitationIdx ? "" : "hidden"
            }`}
          >
            <div className="flex flex-row justify-between">
              <div className="uppercase">jump to highlights</div>
              <div>
                {currentCitationIdx + 1}/{citations.length}
              </div>
              {citations.length > 1 && (
                <div className="flex flex-row gap-x-3">
                  <FaAngleUp
                    className="text-brand cursor-pointer"
                    onClick={() => {
                      const nextCitation = currentCitationIdx - 1;
                      setCurrentCitationIdx(
                        nextCitation < 0 ? citations.length - 1 : nextCitation,
                      );
                    }}
                  />
                  <FaAngleDown
                    className="text-brand cursor-pointer"
                    onClick={() => {
                      setCurrentCitationIdx(
                        (prev) => (prev + 1) % citations.length,
                      );
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className="underline text-brand cursor-pointer"
              onClick={() => {
                updatePageBasedOnCitation();
              }}
            >
              {citation.source_text}
            </div>
          </div>
        );
      })}
    </>
  );
};

const QuestionDocumentInfoNEW = ({
  hideDocInfo,
  questionId,
  documentId,
  changePage,
}: Props) => {
  const { url, questionDocument } = useQuestionDocument({
    questionId: questionId as string,
    documentId: documentId as string,
  });

  if (!questionDocument || !url) {
    return <></>;
  }

  return (
    <div
      className="w-full desktop:max-w-[310px] mx-10 transition-all ease-in-out duration-[600ms] data-[state=true]:max-h-0 data-[state=true]:opacity-0 data-[state=true]:max-w-0 data-[state=true]:min-w-0 data-[state=true]:mx-0"
      data-state={hideDocInfo}
    >
      <Tabs
        tabs={[
          {
            name: "Highlights",
            content: (
              <QuestionDocumentHighlights
                questionId={questionId}
                documentId={documentId}
                hideDocInfo={hideDocInfo}
                changePage={changePage}
              />
            ),
          },
          {
            name: "Doc Info",
            content: (
              <div>
                <DocumentAttachments documentId={documentId} />

                {[
                  { label: "Doc Name", text: questionDocument.document.name },
                  { label: "Doc Type", text: questionDocument.document.type },
                ].map((m) => (
                  <div key={m.label.replaceAll(" ", "_")}>
                    <div className="text-lg mb-2">{m.label}</div>
                    <div className="text-sm mb-4">{m.text}</div>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export const QuestionDocumentInfo = (props: Props) => {
  return (
    <FeatureFlagGate
      enabled={() => <QuestionDocumentInfoNEW {...props} />}
      disabled={() => <QuestionDocumentInfoOLD {...props} />}
      flagKey={(FlagKey) => FlagKey.ChunkHighlighting}
    />
  );
};
