import { useCanDoOrRedirect } from "@/hooks/useCanDo";
import { ProjectPermission } from "@trunk-tools/txt-shared";
import { useParams } from "react-router-dom";
import {
  Button,
  Text,
  useModalToggle,
  Form,
  Takeover,
  Tabs,
  Spinner,
} from "@trunk-tools/ui";
import { useUACProject } from "dataHooks";
import { BsClipboard } from "react-icons/bs";
import { SuspenseErrorBoundary } from "@/layouts/SuspenseErrorBoundary.layout";
import { ConstrainWidth } from "@trunk-tools/ui";
import { SyncInfo, DataSets, Folders } from "./ManageDocumentsTabs.component";
import { RxCross1 } from "react-icons/rx";
import { FiChevronRight } from "react-icons/fi";
import { DocumentSummary } from "./DocumentSummary.component";
import { useInterval } from "@/hooks/useInterval";

const Integration = () => {
  const { uacProjectId } = useParams<{ uacProjectId: string }>();
  const uac_project_id = uacProjectId!;
  const { isOpen, open, close } = useModalToggle();
  const {
    nothingIsSynced,
    uacProjectSyncConfig,
    watchedFolderIds,
    updateUACProjectSyncConfig,
    refetchUACProjectStatus,
    uacProjectStatus,
  } = useUACProject({
    uac_project_id,
  });
  useInterval(
    refetchUACProjectStatus,
    uacProjectStatus.uac_project.lifecycle_status !== "Ready" ? 1000 : null,
  );

  if (uacProjectStatus.uac_project.lifecycle_status !== "Ready") {
    return (
      <ConstrainWidth maxWidth="maximum">
        <div className="flex gap-4">
          <Spinner />
          Initializing project "{uacProjectStatus.uac_project.name}", please
          wait...
        </div>
      </ConstrainWidth>
    );
  }

  return (
    <ConstrainWidth maxWidth="maximum">
      {nothingIsSynced && (
        <>
          <Text.Header className="text-center">
            Connection Successful
          </Text.Header>
          <div className="mb-8 text-center">
            Your account is now linked to TrunkText! Click on the button below
            to start syncing documents, files and other information.
          </div>
        </>
      )}

      <DocumentSummary uacProjectId={uac_project_id} />

      <div className="mt-4">
        <Button icon={BsClipboard} onClick={open}>
          {nothingIsSynced ? "Setup Document Sync" : "Update Document Sync"}
        </Button>
      </div>

      <div>
        <Form.Provider
          initialValues={{
            RFISync: uacProjectSyncConfig.rfis,
            submittalsSync: uacProjectSyncConfig.submittals,
            specSync: uacProjectSyncConfig.specs,
            drawingSync: uacProjectSyncConfig.drawings,
            selectedFolders: watchedFolderIds,
            formSync: uacProjectSyncConfig.forms,
            issueSync: uacProjectSyncConfig.issues,
          }}
          onSubmit={async (
            formFields: Record<string, string | number | string[] | boolean>,
          ) => {
            await updateUACProjectSyncConfig({
              rfis: formFields.RFISync as boolean,
              submittals: formFields.submittalsSync as boolean,
              specs: formFields.specSync as boolean,
              drawings: formFields.drawingSync as boolean,
              folders: formFields.selectedFolders as string[],
              forms: formFields.formSync as boolean,
              issues: formFields.issueSync as boolean,
            });
            close();
          }}
          submitButtonText={false}
        >
          {(form) => (
            <Takeover.Container
              isOpen={isOpen}
              onClose={() => {
                // prevent the form resetting during close animation
                setTimeout(form.handleReset, 500);
                close();
              }}
              title="Manage Documents"
              glassHeader={true}
            >
              <>
                <Takeover.Main>
                  <Takeover.Primary>
                    <SuspenseErrorBoundary>
                      <ConstrainWidth maxWidth="maximum">
                        <Tabs
                          tabs={[
                            {
                              name: "Sync Info",
                              content: (
                                <SyncInfo uacProjectId={uac_project_id} />
                              ),
                            },
                            {
                              name: "Data Sets",
                              content: (
                                <DataSets uacProjectId={uac_project_id} />
                              ),
                            },
                            {
                              name: "Folders",
                              content: (
                                <Folders uacProjectId={uac_project_id} />
                              ),
                            },
                          ]}
                        />
                      </ConstrainWidth>
                    </SuspenseErrorBoundary>
                  </Takeover.Primary>
                </Takeover.Main>
                <Takeover.Footer>
                  <Takeover.FooterButtons
                    leftButtons={[
                      <Button
                        onClick={() => {
                          // prevent the form resetting during close animation
                          setTimeout(form.handleReset, 500);
                          close();
                        }}
                        variant="secondary"
                        icon={RxCross1}
                      >
                        Cancel
                      </Button>,
                    ]}
                    rightButtons={[
                      <Button
                        type="submit"
                        icon={FiChevronRight}
                        isLoading={form.isSubmitting}
                        isDisabled={!form.dirty}
                      >
                        Save Changes
                      </Button>,
                    ]}
                  />
                </Takeover.Footer>
              </>
            </Takeover.Container>
          )}
        </Form.Provider>
      </div>
    </ConstrainWidth>
  );
};

export function IntegrationPage() {
  const { can } = useCanDoOrRedirect({
    permission: ProjectPermission.ManageDocuments,
  });

  if (can) {
    return <Integration />;
  } else {
    return null;
  }
}
