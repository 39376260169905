import { MarkdownFormatter } from "@trunk-tools/ui";
import { UserQuestionConversationItem } from "../UserQuestionConverastionItem.component";
import { FeatureFlagGate } from "@/components/FeatureFlagGate.component";
import { RelevantDocumentsMessage } from "../RelevantDocumentsConversationItem.component";
import { AnswerMessage } from "../../AnswerMessage.component";
import { ConversationItems } from "@trunk-tools/txt-shared";
import { useMe } from "dataHooks";

export type QuestionAndSeparateRelevantDocumentsProps = {
  indexInConversationItems: number;
  conversationItemsLength: number;
  showLatestQuestionAnswer: boolean;
  isLatestQuestionAnswerRendered: boolean;
  item: Extract<ConversationItems, { type: "question" }>;
};

export const QuestionAndSeparateRelevantDocuments = ({
  conversationItemsLength,
  indexInConversationItems,
  isLatestQuestionAnswerRendered,
  showLatestQuestionAnswer,
  item,
}: QuestionAndSeparateRelevantDocumentsProps) => {
  const { me, meInitials } = useMe();
  const isLastItem = indexInConversationItems === conversationItemsLength - 1;

  let showAnswer = false;
  let isAnswerRendered = false;
  if (isLastItem) {
    showAnswer = showLatestQuestionAnswer;
    isAnswerRendered = isLatestQuestionAnswerRendered;
  }

  return (
    <div key={item.question.id}>
      <UserQuestionConversationItem
        initials={meInitials}
        name={`${me.first_name} ${me.last_name}`}
      >
        <MarkdownFormatter text={item.question.question_text} />
      </UserQuestionConversationItem>

      {(!isLastItem || showAnswer) && (
        <div
          className={`opacity-0 ${
            !isLastItem || isAnswerRendered
              ? "transition-all ease-in duration-800 opacity-100"
              : ""
          }`}
        >
          <FeatureFlagGate
            enabled={() => (
              <RelevantDocumentsMessage question={item.question} />
            )}
            disabled={() => null}
            flagKey={(FlagKey) => FlagKey.SearchResults}
          />

          <AnswerMessage
            embeddedQuestion={false}
            index={indexInConversationItems}
            question={item.question}
            last={isLastItem}
          />
        </div>
      )}
    </div>
  );
};
