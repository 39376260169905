import useSWR from "swr";
import { api } from "./helpers/APIClient";
import { useBusinessProjectId } from "@/hooks/userUrlParams";
import { useParams } from "react-router-dom";

export const useProjectDocumentsStatus = () => {
  const { currentProject } = useCurrentProject();

  const {
    data: { documents_status },
  } = useSWR(
    "alskdjflaksjdflaskdf",
    () => api.getProjectDocumentsStatus({ project_id: currentProject.id }),
    {
      suspense: true,
    },
  );

  return { documents_status };
};

export const useProjectDocumentVersions = () => {
  const { projectId } = useBusinessProjectId();
  const {
    data: { versions },
  } = useSWR(
    "projects/documents/versions",
    () => api.getProjectDocumentParserVersions({ project_id: projectId }),
    {
      suspense: true,
    },
  );

  return {
    versions,
  };
};

export const useMaybeCurrentProject = () => {
  const { projects } = useUserProjects({});
  const { projectId } = useBusinessProjectId();

  const currentProject = projects.find((p) => p.id === projectId);

  return { currentProject };
};

export const useCurrentProject = () => {
  const { projects } = useUserProjects({});
  const { projectId } = useBusinessProjectId();

  const currentProject = projects.find((p) => p.id === projectId);

  // edge case: if a user follows a URL to a project they don't have
  // access to their current project can be undefined
  if (!currentProject) {
    throw new Error(
      "This hook should only be used in the context of an element wrapped in the project route wrapper",
    );
  }

  return { currentProject };
};

export const useUserBelongsToProject = () => {
  const { projects } = useUserProjects({});
  const { projectId } = useParams<{ projectId: string }>();

  return {
    userBelongsToProject: projects.some((p) => p.id === projectId),
  };
};

export const useUserProjects = ({
  sortByName,
}: {
  sortByName?: "asc" | "desc";
}) => {
  const fetch = async (): Promise<
    Awaited<ReturnType<typeof api.userProjects>>["projects"]
  > => {
    const data = await api.userProjects({
      sortByName,
    });
    return data.projects;
  };

  const { data: projects } = useSWR(["userProjects", sortByName], fetch, {
    suspense: true,
  });

  return {
    projects,
  };
};

export const useSampleQuestions = ({
  num_samples = 3,
}: {
  num_samples?: number;
}) => {
  const { currentProject } = useCurrentProject();

  const { data: sampleQuestions } = useSWR(
    `${currentProject.id}/sample-questions`,
    () =>
      api.getSampleQuestions({
        project_id: currentProject.id,
        num_samples,
      }),
    {
      suspense: true,
    },
  );

  return {
    sampleQuestions,
  };
};
