import dayjs from "dayjs";
import whiteWand from "public/white_wand.svg";
import {
  useConversationsSections,
  ConversationsSection,
} from "@/dataHooks/conversations.dataHook";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { useInterval } from "@/hooks/useInterval";
import { ConversationItemType } from "@trunk-tools/txt-shared";
import { useParams } from "react-router-dom";

const Section = ({
  section,
  setSidebarOpen,
}: {
  section: ConversationsSection;
  setSidebarOpen: (arg: boolean) => void;
}) => {
  const navigate = useIdNavigate();
  const { conversationId } = useParams();

  return (
    <>
      <div className="uppercase text-xs my-2 tracking-normal">
        {section.title}
      </div>
      {section.conversations.map((item) => {
        const summary = item.summary ? item.summary : "Untitled Conversation";

        let content: React.ReactNode;
        let pendoId: string;
        if (
          item.conversationItems.length > 0 &&
          item.conversationItems[0].type ===
            ConversationItemType.ScheduleAgentOverview
        ) {
          pendoId = "schedule-agent-conversation-list-item";
          content = (
            <div className="flex flex-row gap-x-4 items-center">
              <img
                src={whiteWand}
                alt="schedule agent conversation"
                className="top-1/2 w-6 h-6"
              />
              <div className="flex flex-col gap-y-1 flex-grow">
                <div>{summary}</div>
                <div className="text-xs flex-grow">
                  {dayjs(item.created_at).format("MMM D, YYYY, h:mm A")}
                </div>
              </div>
              {item.conversationItems[0].read_at === null &&
                item.conversationItems[0].status === "FINISHED" && (
                  <div className="w-5 h-5 bg-green-500 rounded-full text-center text-xs/6">
                    1
                  </div>
                )}
            </div>
          );
        } else if (
          item.conversationItems.length > 0 &&
          item.conversationItems[0].type === ConversationItemType.Question &&
          item.agent
        ) {
          pendoId = "question-bank-agent-conversation-list-item";
          content = (
            <div className="flex flex-row gap-x-4 items-center">
              <img
                src={whiteWand}
                alt="question bank agent conversation"
                className="top-1/2 w-6 h-6"
              />
              <div className="flex flex-col gap-y-1 flex-grow">
                <div>{summary}</div>
                <div className="text-xs flex-grow">
                  {dayjs(item.created_at).format("MMM D, YYYY, h:mm A")}
                </div>
              </div>
              {item.conversationItems[0].read_at === null &&
                (item.conversationItems[0].status === "ANSWERED" ||
                  item.conversationItems[0].status === "FINISHED") && (
                  <div className="w-5 h-5 bg-green-500 rounded-full text-center text-xs/6">
                    1
                  </div>
                )}
            </div>
          );
        } else {
          pendoId = "user-conversation-list-item";
          content = summary;
        }

        return (
          <div
            key={item.id}
            className={`py-5 px-4 rounded shadow-lg transition duration-300 ease-in-out ${
              item.id === conversationId
                ? "bg-[#204E6F89]"
                : "hover:bg-[#2e495d5e]"
            }`}
            onClick={() => {
              navigate(`/conversations/${item.id}`);
              setSidebarOpen(false);
            }}
          >
            <div
              className="w-full whitespace-nowrap overflow-hidden text-ellipsis tracking-normal font-poppins font-semibold"
              data-pendo-id={pendoId}
            >
              {content}
            </div>
          </div>
        );
      })}
    </>
  );
};

export const ConversationsList = ({
  setSidebarOpen,
}: {
  setSidebarOpen: (arg: boolean) => void;
}) => {
  const { conversationsSections, refreshUserConversations } =
    useConversationsSections();

  const allConversationsHaveSummary = conversationsSections.every((section) =>
    section.conversations.every((conversation) => conversation.summary),
  );

  const anyConversationHasUnread = conversationsSections.some((section) =>
    section.conversations.some((conversation) => {
      return (
        conversation.agent &&
        conversation.conversationItems.some((item) => item.read_at === null)
      );
    }),
  );

  useInterval(
    refreshUserConversations,
    allConversationsHaveSummary && !anyConversationHasUnread ? 60_000 : 2_000,
  );

  return (
    <div className="overflow-y-scroll no-scrollbar flex flex-col cursor-pointer">
      {conversationsSections.map((section) => (
        <Section
          key={Math.random()}
          section={section}
          setSidebarOpen={setSidebarOpen}
        />
      ))}
    </div>
  );
};
