import { FeatureFlagGate } from "@/components/FeatureFlagGate.component";
import {
  QuestionAndSeparateRelevantDocuments,
  QuestionAndSeparateRelevantDocumentsProps,
} from "./QuestionAndSeparateRelevantDocuments.component";
import {
  CondensedQuestionAndAnswer,
  CondensedQuestionAndAnswerProps,
} from "./CondensedQuestionAndAnswer.component";

type QuestionAndAnswerProps = {
  condensedQuestionAndAnswerProps: CondensedQuestionAndAnswerProps;
  questionAndSeparateRelevantDocumentsProps: QuestionAndSeparateRelevantDocumentsProps;
};

export const QuestionAndAnswer = ({
  questionAndSeparateRelevantDocumentsProps,
  condensedQuestionAndAnswerProps,
}: QuestionAndAnswerProps) => {
  return (
    <FeatureFlagGate
      enabled={() => (
        <CondensedQuestionAndAnswer {...condensedQuestionAndAnswerProps} />
      )}
      disabled={() => (
        <QuestionAndSeparateRelevantDocuments
          {...questionAndSeparateRelevantDocumentsProps}
        />
      )}
      flagKey={(FlagKey) => FlagKey.CondenseReplyModule}
    />
  );
};
