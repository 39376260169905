import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { useDocument } from "@/dataHooks/documents.dataHook";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, DocumentViewerNEW, ErrorDeadEnd } from "@trunk-tools/ui";
import { download } from "@/utils/download";
import tWhite from "public/T.svg";
import { GrDocumentDownload } from "react-icons/gr";
import { TfiClose } from "react-icons/tfi";
import { useQuestionDocument } from "dataHooks";
import { QuestionDocumentInfo } from "@/components/QuestionsAndAnswers/QuestionDocumentInfo.component";
import { useIdNavigate } from "@/hooks/useIdNavigate";

const QuestionDocumentPage = ({
  questionId,
  documentId,
}: {
  questionId: string;
  documentId: string;
}) => {
  const idNavigate = useIdNavigate();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width < 768;
  const [hideDocInfo, setHideDocInfo] = useState(isMobile);
  const [downloading, setDownloading] = useState(false);

  const [documentViewerKey, setDocumentViewerKey] = useState<string | null>(
    null,
  );
  const [additionalProps, setAdditionalProps] = useState<
    | {
        goToPage?: number;
        sourceText?: string;
      }
    | Record<string, never>
  >({});
  const { document, ext } = useDocument({ documentId });
  const { questionDocument } = useQuestionDocument({ questionId, documentId });
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get("conversationId");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const forceDocViewerRerender = (newKey: string) => {
    /**
     * This is a bit of a hack due to the architecture of the DocumentViewer component.
     * We want to re-render the DocumentViewer component when the page changes. But, it's
     * pretty difficult to a lot of prop drilling or callback func passing to achieve this.
     * So, an easy way to force a re-render is to change the key of the DocumentViewer component.
     * The value of the key doesn't actually matter. We just want to change it whenever
     * this function to change the page gets called.
     */
    if (documentViewerKey === null) {
      setDocumentViewerKey(newKey);
    } else {
      setDocumentViewerKey(null);
    }
  };

  const changePage = ({
    page,
    sourceText,
  }: {
    page: number;
    sourceText?: string;
  }) => {
    setAdditionalProps({
      goToPage: page,
      sourceText,
    });

    forceDocViewerRerender(`${page}-${sourceText}`);
  };

  /**
   * When switching documents while in the doc viewer, we need to force a re-render
   */
  useEffect(() => {
    forceDocViewerRerender(`${documentId}`);
  }, [documentId]);

  useEffect(() => {
    if (questionDocument && ext == "pdf") {
      setAdditionalProps({
        goToPage:
          "locator" in questionDocument
            ? Number(questionDocument.locator)
            : undefined,
        sourceText:
          "source_text" in questionDocument
            ? String(questionDocument.source_text)
            : "",
      });
    }
  }, [questionDocument]);

  const exitPage = () => {
    let path = "/conversations";
    if (conversationId) {
      path = `/conversations/${conversationId}`;
    }

    idNavigate(path);
  };

  const url = document.url;
  if (!url) {
    return <ErrorDeadEnd message="Document cannot be viewed via URL" />;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row flex-wrap my-8 mx-4 gap-x-4 items-center">
        <img src={tWhite} alt="trunktext logo" className="w-8 h-8" />
        <p className="uppercase desktop:grow text-xs desktop:text-base max-w-[100px] desktop:max-w-full">
          {document.name}
        </p>

        <div className="flex gap-4 ml-auto">
          <Button
            variant="secondary"
            size={isMobile ? "sm" : "md"}
            onClick={() => {
              setHideDocInfo(!hideDocInfo);
            }}
            icon={hideDocInfo ? FaRegEye : FaRegEyeSlash}
          >
            <span className="hidden desktop:block">
              {hideDocInfo ? "Show" : "Hide"} Doc Info
            </span>
            <span className="block desktop:hidden">Info</span>
          </Button>

          <span data-pendo-id="download-document-from-viewer">
            <Button
              variant="primary"
              size={isMobile ? "sm" : "md"}
              isLoading={downloading}
              onClick={() => {
                setDownloading(true);
                download(url, document.name).then(() => {
                  setDownloading(false);
                });
              }}
              icon={GrDocumentDownload}
            >
              <span className="hidden desktop:block">Download</span>
            </Button>
          </span>
        </div>
        <TfiClose
          className="ml-6 cursor-pointer"
          onClick={() => {
            exitPage();
          }}
          size={isMobile ? 25 : 30}
        />
      </div>
      <div className="flex flex-row h-full">
        <div
          className={`w-full m-4 ${
            isMobile
              ? "transition-all ease-in-out duration-[600ms] data-[state=false]:max-h-0 data-[state=false]:opacity-0 data-[state=false]:max-w-0 data-[state=false]:min-w-0 data-[state=false]:mx-0"
              : ""
          }`}
          data-state={hideDocInfo}
        >
          <DocumentViewerNEW
            key={documentViewerKey}
            type={ext as string}
            isOpen={true}
            onClose={() => exitPage()}
            title={document.name}
            url={url}
            onDownloadClick={() => download(url, document.name)}
            {...additionalProps}
          />
        </div>
        <QuestionDocumentInfo
          hideDocInfo={hideDocInfo}
          questionId={questionId}
          documentId={documentId}
          changePage={changePage}
        />
      </div>
    </div>
  );
};

const DocumentPageInner = ({ documentId }: { documentId: string }) => {
  const idNavigate = useIdNavigate();
  const { document, ext } = useDocument({ documentId: documentId! });

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width < 768;
  const [hideDocInfo, setHideDocInfo] = useState(isMobile);
  const [downloading, setDownloading] = useState(false);
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get("conversationId");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const exitPage = () => {
    let path = "/conversations";
    if (conversationId) {
      path = `/conversations/${conversationId}`;
    }

    idNavigate(path);
  };

  const url = document.url;
  if (!url) {
    return <ErrorDeadEnd message="Document cannot be viewed via URL" />;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row flex-wrap my-8 mx-4 gap-x-4 items-center">
        <img src={tWhite} alt="trunktext logo" className="w-8 h-8" />
        <p className="uppercase desktop:grow text-xs desktop:text-base max-w-[100px] desktop:max-w-full">
          {document.name}
        </p>

        <div className="flex gap-4 ml-auto">
          <Button
            variant="secondary"
            size={isMobile ? "sm" : "md"}
            onClick={() => {
              setHideDocInfo(!hideDocInfo);
            }}
            icon={hideDocInfo ? FaRegEye : FaRegEyeSlash}
          >
            <span className="hidden desktop:block">
              {hideDocInfo ? "Show" : "Hide"} Doc Info
            </span>
            <span className="block desktop:hidden">Info</span>
          </Button>

          <span data-pendo-id="download-document-from-viewer">
            <Button
              variant="primary"
              size={isMobile ? "sm" : "md"}
              isLoading={downloading}
              onClick={() => {
                setDownloading(true);
                download(url, document.name).then(() => {
                  setDownloading(false);
                });
              }}
              icon={GrDocumentDownload}
            >
              <span className="hidden desktop:block">Download</span>
            </Button>
          </span>
        </div>

        <TfiClose
          className="ml-4 cursor-pointer"
          onClick={() => {
            exitPage();
          }}
          size={isMobile ? 25 : 30}
        />
      </div>
      <div className="flex flex-row h-full">
        <div
          className={`w-full m-4 ${
            isMobile
              ? "transition-all ease-in-out duration-[600ms] data-[state=false]:max-h-0 data-[state=false]:opacity-0 data-[state=false]:max-w-0 data-[state=false]:min-w-0 data-[state=false]:mx-0"
              : ""
          }`}
          data-state={hideDocInfo}
        >
          <DocumentViewerNEW
            type={ext as string}
            isOpen={true}
            onClose={() => exitPage()}
            title={document.name}
            url={url}
            onDownloadClick={() => download(url, document.name)}
          />
        </div>
      </div>
    </div>
  );
};

export const DocumentPage = () => {
  const { documentId } = useParams();
  const [searchParams] = useSearchParams();
  const questionId = searchParams.get("questionId");

  if (!documentId) {
    return <ErrorDeadEnd message="Document not found" />;
  }

  if (questionId) {
    return (
      <QuestionDocumentPage questionId={questionId} documentId={documentId} />
    );
  }

  return <DocumentPageInner documentId={documentId} />;
};
