import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import trunkToolsWhite from "public/trunk_tools_white.svg";
import { Button, GradientBackdrop, Select, TextInput } from "@trunk-tools/ui";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import TextareaAutosize from "react-textarea-autosize";
import { useEffect, useState } from "react";
import plusWhite from "public/plus_white.svg";
import plusGrey from "public/plus_grey.svg";
import { FiChevronRight, FiChevronLeft, FiCheck, FiX } from "react-icons/fi";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import {
  useCreateQuestionBankAgent,
  useProjectUsers,
  useUpdateQuestionBankAgent,
} from "@/dataHooks/agents.dataHook";
import { useParams } from "react-router-dom";
import { useAgentConfig } from "@/dataHooks/agents.dataHook";
import { toInputValues } from "@trunk-tools/txt-shared";
import { useMe } from "dataHooks";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type QuestionCardProps = {
  question: string;
  edit: () => void;
  remove: () => void;
};

const timeOptions = Array.from({ length: 48 }, (_, i) => {
  const localTimeZone = dayjs().tz(dayjs.tz.guess()).format("z");
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? "00" : "30";
  const ampm = hour < 12 ? "AM" : "PM";
  const hour12 = hour % 12 === 0 ? 12 : hour % 12;

  return {
    label: `${hour12}:${minute} ${ampm} ${localTimeZone}`,
    value: `${hour}:${minute}`,
  };
});

const QuestionCard = ({ question, edit, remove }: QuestionCardProps) => {
  return (
    <div className="bg-[#4d7d67] bg-opacity-25 b-1 rounded p-4 font-poppins">
      <p>{question}</p>
      <div className="font-bold uppercase flex flex-row justify-between mt-4">
        <p onClick={edit} className="text-brand cursor-pointer">
          Edit
        </p>
        <p onClick={remove} className="cursor-pointer">
          Delete
        </p>
      </div>
    </div>
  );
};

type DayOfWeek = "Su" | "Mo" | "Tu" | "We" | "Th" | "Fr" | "Sa";
type Frequency = "day" | "week" | "never";

type TabHeadingProps = {
  text: string;
  selected: boolean;
  complete: boolean;
  onClick?: () => void;
};
const TabHeading = ({ text, selected, complete, onClick }: TabHeadingProps) => {
  // logic: If currently active, is white and has underline.
  // if completed, checkbox turns blue instead of white.
  return (
    <div
      className="uppercase desktop:mb-6 text-sm desktop:text-lg cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-row gap-x-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
            fill={complete ? "#005A9C" : "white"}
            stroke={complete ? "#005A9C" : "white"}
            fillOpacity={complete ? "1" : "0.32"}
            opacity={complete ? "1" : "0.64"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 9L10.5 14.5L8 12"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className={selected ? "" : "opacity-50"}>{text}</p>
      </div>
      {selected && (
        <div className="w-full h-2 left-0 top-[4px] relative">
          <div className="w-full h-0.5 left-0 top-[5px] relative bg-white" />
          <div className="w-2 h-2 left-1/2 top-0 relative bg-white rounded-full" />
        </div>
      )}
    </div>
  );
};
const AgentInfoCard = () => {
  return (
    <div className="hidden desktop:block w-1/3 min-w-[250px] pr-6">
      <div className="uppercase mb-6 text-lg">agents on trunktext</div>
      <div className="gap-y-4 text-md mb-8 font-poppins font-normal">
        <p className="mb-8">
          An Agent allows you to combine multiple questions and/or requests
          about critical project topics, which can then be scheduled and run
          automatically.
        </p>
        <p className="mb-8">
          Use Agents to automate almost any repetitive project task, from
          generating OAC Reports to receiving updates on approved submittals or
          outstanding RFIs.
        </p>
        <p className="mb-8">
          For best results, make each question or request as focused, clear, and
          explicit as possible. For example, if you want to see all outstanding
          RFIs and all unapproved Submittals, enter each request as a separate
          question.{" "}
        </p>
      </div>
    </div>
  );
};

const CreateAgentFooter = ({
  canGoBack,
  canGoForward,
  canSave,
  onBack,
  onForward,
  onSave,
  isDesktop,
}: {
  canGoBack: boolean;
  canGoForward: boolean;
  canSave: boolean;
  isDesktop: boolean;
  onBack: () => void;
  onForward: () => void;
  onSave: () => void;
}) => {
  const idNavigate = useIdNavigate();
  return (
    <footer className="p-6 flex flex-row gap-x-6">
      <Button
        onClick={() => {
          idNavigate("/agents");
        }}
        icon={FiX}
        isInline
        variant="secondary"
        size={isDesktop ? "lg" : "md"}
      >
        <p className="hidden desktop:block">Cancel</p>
      </Button>
      <Button
        onClick={onBack}
        icon={FiChevronLeft}
        isInline
        variant="secondary"
        isDisabled={!canGoBack}
        size={isDesktop ? "lg" : "md"}
      >
        <p>Back</p>
      </Button>
      <span className="flex-grow"></span>
      <Button
        isInline
        iconOnRight
        variant="secondary"
        icon={FiChevronRight}
        onClick={onForward}
        size={isDesktop ? "lg" : "md"}
        isDisabled={!canGoForward}
      >
        <p>Next</p>
      </Button>
      <Button
        onClick={onSave}
        icon={FiCheck}
        isInline
        isDisabled={!canSave}
        variant="primary"
        size={isDesktop ? "lg" : "md"}
      >
        <p className="hidden desktop:block">Save Changes</p>
      </Button>
    </footer>
  );
};

const TabOneContents = ({
  currentQuestion,
  addToQuestionSet,
  setCurrentQuestion,
  editing,
  cancelEdits,
  saveEditedQuestion,
  questionSet,
  editQuestion,
  removeFromQuestionSet,
  user,
  setUser,
}: {
  currentQuestion: string;
  addToQuestionSet: () => void;
  setCurrentQuestion: (value: string) => void;
  editing: number | null;
  cancelEdits: () => void;
  saveEditedQuestion: () => void;
  questionSet: string[];
  editQuestion: (index: number) => void;
  removeFromQuestionSet: (index: number) => void;
  user: string;
  setUser: (value: string) => void;
}) => {
  const { projectUsers } = useProjectUsers();
  const { me } = useMe();

  let hasMe = false;
  const userOptions = projectUsers.users.map((user) => {
    if (user.id === me?.id) {
      hasMe = true;
    }
    return {
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
    };
  });
  if (!hasMe) {
    userOptions.push({
      label: `${me?.first_name} ${me?.last_name}`,
      value: me?.id,
    });
  }

  return (
    <>
      <div className="hidden desktop:block text-[22px] font-normal uppercase leading-[33px] mb-6">
        1 / 2 - create question set
      </div>
      <div className="w-full flex flex-col desktop:flex-row gap-x-6">
        <div className="desktop:w-1/2">
          <div className="text-white text-[10px] font-normal font-['Izoard Soft'] uppercase leading-[15px]">
            write a question or request
          </div>
          <TextareaAutosize
            className="w-full h-full text-base p-6 rounded tracking-normal pr-12 mt-5 mb-4"
            rows={1}
            style={{
              color: "#324353",
              border: "1px solid #F5BF40",
              boxShadow: "0px 1px 4px 0px #40300C7A, 0px 0px 8px 0px #F9DFA17A",
            }}
            value={currentQuestion}
            placeholder="Type a question..."
            onChange={(e) => setCurrentQuestion(e.target.value)}
          />
          <div className="flex flex-row">
            <span className="hidden desktop:block flex-grow" />
            {editing === null ? (
              <div>
                <Button
                  isInline
                  variant="success"
                  onClick={addToQuestionSet}
                  isDisabled={currentQuestion === ""}
                >
                  <img
                    src={currentQuestion === "" ? plusGrey : plusWhite}
                    alt="run agent"
                    className="w-3 h-3 desktop:w-4 desktop:h-4"
                  />
                  <p className="text-sm uppercase">Add To Question Set</p>
                </Button>
              </div>
            ) : (
              <div className="w-full flex flex-row justify-between">
                <Button isInline variant="secondary" onClick={cancelEdits}>
                  <p className="text-sm uppercase">cancel</p>
                </Button>
                <Button
                  isInline
                  variant="success"
                  onClick={saveEditedQuestion}
                  isDisabled={currentQuestion === questionSet[editing]}
                >
                  <img
                    src={
                      currentQuestion === questionSet[editing]
                        ? plusGrey
                        : plusWhite
                    }
                    alt="run agent"
                    className="w-3 h-3 desktop:w-4 desktop:h-4"
                  />
                  <p className="text-sm uppercase">save</p>
                </Button>
              </div>
            )}
          </div>
          <div>
            <span>For user</span>
            <Select
              items={userOptions}
              value={user}
              name={"select_user"}
              onChange={(nextValue) => {
                setUser(nextValue as string);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-4 w-full desktop:w-1/2">
          <div className="desktop:hidden w-full mt-4 mb-2 h-[2px] shadow-separator"></div>
          <div className="uppercase text-xs">question set</div>
          {questionSet.length === 0 && (
            <div className="font-poppins font-light">No Questions Added</div>
          )}
          {questionSet.map((question, index) => (
            <QuestionCard
              key={index}
              question={question}
              edit={() => editQuestion(index)}
              remove={() => removeFromQuestionSet(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const TabTwoContents = ({
  agentName,
  setAgentName,
  frequencyUnit,
  setFrequencyUnit,
  time,
  setTime,
  selected,
  toggleDay,
}: {
  agentName: string;
  setAgentName: (value: string) => void;
  frequencyUnit: string;
  setFrequencyUnit: (value: Frequency) => void;
  time: string;
  setTime(value: string): void;
  selected: (day: DayOfWeek) => boolean;
  toggleDay: (day: DayOfWeek) => void;
}) => {
  return (
    <>
      <div className="hidden desktop:block text-[22px] font-normal uppercase leading-[33px] mb-6">
        2 / 2 - agent info & scheduling
      </div>
      <div className="desktop:w-[330px]">
        <p className="uppercase my-3">agent name</p>
        <TextInput
          rounded
          name="agent name"
          value={agentName}
          onChange={setAgentName}
        />
        <p className="uppercase my-3">frequency</p>
        <div className="w-full h-14 justify-start items-center gap-2 inline-flex">
          <div className="flex-[30%]">Repeat every</div>
          <div className="flex-[30%]">
            <Select
              items={["never", "day", "week"].map((unit) => ({
                label: unit,
                value: unit,
              }))}
              onChange={(nextValue) => {
                setFrequencyUnit(nextValue as Frequency);
              }}
              value={frequencyUnit}
              name={""}
            />
          </div>
        </div>
        {frequencyUnit === "week" && (
          <>
            <p className="uppercase my-3">repeat on</p>
            <div className="h-9 justify-start items-start gap-[13px] inline-flex">
              {(["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"] as DayOfWeek[]).map(
                (day) => (
                  <div
                    key={day}
                    onClick={() => toggleDay(day)}
                    className="w-9 h-9 relative cursor-pointer"
                  >
                    <div className="w-9 h-9 left-0 top-0 absolute justify-start items-start gap-2.5 inline-flex">
                      <div
                        className={`grow shrink basis-0 self-stretch rounded-full border-2 border-white ${
                          selected(day) ? "bg-[#005a9c]" : ""
                        }`}
                      />
                    </div>
                    <div className="w-9 left-0 top-[7px] absolute text-center text-white text-sm font-normal font-['Poppins'] leading-[21px]">
                      {day}
                    </div>
                  </div>
                ),
              )}
            </div>
          </>
        )}
        {frequencyUnit !== "never" && (
          <>
            <p className="uppercase my-3">run at</p>
            <div className="w-full h-9 justify-start items-start gap-[13px] inline-flex">
              <Select
                items={timeOptions}
                onChange={(nextValue) => {
                  setTime(nextValue);
                }}
                value={time}
                name={""}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const NewAgentPage = () => {
  const idNavigate = useIdNavigate();
  const { me } = useMe();

  const [currentQuestion, setCurrentQuestion] = useState("");
  const [editing, setEditing] = useState<number | null>(null);
  const [frequencyUnit, setFrequencyUnit] = useState<Frequency>("week");
  const [time, setTime] = useState<string>("");
  const [repeatOn, setRepeatOn] = useState<DayOfWeek[]>([]);
  const [agentName, setAgentName] = useState<string>("");
  const [user, setUser] = useState<string>(me?.id || "");
  const [stage, setStage] = useState<number | null>(null);

  const [questionSet, setQuestionSet] = useState<string[]>([]);

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const { agentId } = useParams<{ agentId: string }>();
  const { agentConfig } = useAgentConfig({
    agentId: agentId || "",
  });

  const to24HourTimeValue = (time: string) => {
    const [hour, minute] = time.split(" ")[0].split(":");
    const isPM = time.toLowerCase().includes("pm");
    const hour24 = parseInt(hour === "12" ? "0" : hour) + (isPM ? 12 : 0);
    return `${hour24}:${minute}`;
  };

  useEffect(() => {
    if (me.role !== "SUPER_ADMIN") {
      setStage(2);
    }
    if (me.role === "SUPER_ADMIN" && stage === null) {
      setStage(1);
    }
  }, [me, stage]);

  useEffect(() => {
    if (agentConfig) {
      setAgentName(agentConfig.name);
      setQuestionSet(agentConfig.questions);
      const cron = toInputValues(
        agentConfig.cron_expression || "",
        agentConfig.server_timezone,
      );
      setFrequencyUnit(cron.frequencyUnit);
      setTime(cron.time ? to24HourTimeValue(cron.time) : "");
      setRepeatOn(cron.days.filter((day) => day !== undefined));
      setUser(agentConfig.user_id);
    }
  }, [agentConfig]);

  const { createQuestionBankAgent } = useCreateQuestionBankAgent();
  const { updateQuestionBankAgent } = useUpdateQuestionBankAgent();

  const addToQuestionSet = () => {
    setQuestionSet([...questionSet, currentQuestion]);
    setCurrentQuestion("");
  };

  const saveEditedQuestion = () => {
    const newQuestionSet = questionSet.map((question, i) =>
      i === editing ? currentQuestion : question,
    );
    setQuestionSet(newQuestionSet);
    setCurrentQuestion("");
    setEditing(null);
  };

  const cancelEdits = () => {
    setCurrentQuestion("");
    setEditing(null);
  };

  const removeFromQuestionSet = (index: number) => {
    const newQuestionSet = questionSet.filter((_, i) => i !== index);
    setQuestionSet(newQuestionSet);
  };

  const editQuestion = (index: number) => {
    setCurrentQuestion(questionSet[index]);
    setEditing(index);
  };

  const selected = (day: DayOfWeek) => {
    return repeatOn.includes(day);
  };

  const toggleDay = (day: DayOfWeek) => {
    if (repeatOn.includes(day)) {
      setRepeatOn(repeatOn.filter((d) => d !== day));
    } else {
      setRepeatOn([...repeatOn, day]);
    }
  };

  return (
    <GradientBackdrop>
      <div className="flex flex-col h-full">
        <div className="px-6">
          <div className="h-25 flex flex-row gap-x-6 py-3 items-center">
            <img
              src={trunkToolsWhite}
              alt="trunk tools logo"
              className="w-13 h-8"
            />
            <p className="uppercase text-sm">agent manager</p>
          </div>
          <div className="flex flex-row gap-x-4 text-md desktop:text-xl mb-8">
            <p
              className="underline cursor-pointer"
              onClick={() => {
                idNavigate("/conversations/new");
              }}
            >
              Home
            </p>
            <p>&gt;</p>
            <p
              className="underline cursor-pointer"
              onClick={() => {
                idNavigate("/agents");
              }}
            >
              Manage Agents
            </p>
            <p>&gt;</p>
            <p>Create New Agent</p>
          </div>

          <div className="flex flex-row gap-x-4 text-xl mb-8">
            {me.role === "SUPER_ADMIN" && (
              <TabHeading
                text="Question Set"
                selected={stage === 1}
                complete={questionSet.length > 0}
                onClick={() => setStage(1)}
              />
            )}
            <TabHeading
              text="Agent Info & Scheduling"
              selected={stage === 2}
              complete={agentName !== ""}
              onClick={() => setStage(2)}
            />
          </div>
          <div className="flex flex-row gap-x-12">
            <AgentInfoCard />
            <div className="w-full">
              {stage === 1 && (
                <TabOneContents
                  currentQuestion={currentQuestion}
                  addToQuestionSet={addToQuestionSet}
                  setCurrentQuestion={setCurrentQuestion}
                  editing={editing}
                  cancelEdits={cancelEdits}
                  saveEditedQuestion={saveEditedQuestion}
                  questionSet={questionSet}
                  editQuestion={editQuestion}
                  removeFromQuestionSet={removeFromQuestionSet}
                  user={user}
                  setUser={setUser}
                />
              )}
              {stage === 2 && (
                <TabTwoContents
                  agentName={agentName}
                  setAgentName={setAgentName}
                  frequencyUnit={frequencyUnit}
                  time={time}
                  setTime={setTime}
                  setFrequencyUnit={setFrequencyUnit}
                  selected={selected}
                  toggleDay={toggleDay}
                />
              )}
            </div>
          </div>
        </div>
        <span className="flex-grow" />
        <div className="w-full mt-4 mb-2 h-[2px] shadow-separator"></div>
        <CreateAgentFooter
          canGoBack={stage !== 1 && me.role === "SUPER_ADMIN"}
          canGoForward={stage !== 2 && me.role === "SUPER_ADMIN"}
          canSave={
            questionSet.length > 0 &&
            agentName !== "" &&
            (frequencyUnit === "never" ||
              (time !== "" && (frequencyUnit === "day" || repeatOn.length > 0)))
          }
          onBack={() => setStage((stage || 2) - 1)}
          onForward={() => setStage((stage || 1) + 1)}
          onSave={() => {
            let t: string | null = time;
            let repeat: DayOfWeek[] | null = repeatOn;
            let frequency: Frequency | null = frequencyUnit;

            if (frequency === "never") {
              t = null;
              repeat = null;
              frequency = null;
            }
            if (agentId) {
              updateQuestionBankAgent({
                time_zone: dayjs.tz.guess(),
                agent_id: agentId,
                questions: questionSet,
                name: agentName,
                frequency_unit: frequency,
                time: t,
                repeat_on: repeat,
                user: user,
              }).then(() => {
                idNavigate("/agents");
              });
            } else {
              createQuestionBankAgent({
                time_zone: dayjs.tz.guess(),
                questions: questionSet,
                name: agentName,
                frequency_unit: frequency,
                time: t,
                repeat_on: repeat,
                user: user,
              }).then(() => {
                idNavigate(`/agents`);
              });
            }
          }}
          isDesktop={isDesktop}
        />
      </div>
    </GradientBackdrop>
  );
};
